<template>
<div class="container">
  <group-edit-form
    payload-process="create"
    :detailed-source-group="detailedSourceGroup"
  />
</div>
</template>

<script>
import GroupEditForm from "@/components/baseGroups/groupEditForm";

export default {
  name: "GroupCreate",
  components: {GroupEditForm},
  data() {
    return {
      detailedSourceGroup: {
        name: "",
        description: "",
        users_ids: [],
        sources_ids: []
      }
    };
  },

}
</script>

<style scoped>

</style>